.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-image {
  width: 200px;
  height: 39px;
  object-fit: cover;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link02 {
  text-decoration: none;
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-tripleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon02 {
  width: 24px;
  height: 24px;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link03 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-link04 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-link05 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tenunits);
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-container02 {
  width: 100%;
  height: 597px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f1f1f1;
}
.home-hero {
  width: 100%;
  height: 513px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: #f1f1f1;
}
.home-image1 {
  top: 30px;
  right: 160px;
  width: 387px;
  bottom: 30px;
  height: 419px;
  margin: 0px;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  transition: 0.3s;
  border-color: #ffffff;
  border-style: solid;
  border-width: 10px;
  border-radius: var(--dl-radius-radius-radius8);
}

.home-heading1 {
  top: 67px;
  left: 120px;
  color: #040404;
  position: absolute;
}
.home-container03 {
  flex: 0 0 auto;
  width: 713px;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-hero-text-container {
  left: 47px;
  width: 452px;
  bottom: -176px;
  height: 47px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.home-cta-btn-container {
  top: 0px;
  left: 0px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-cta-btn {
  top: 5px;
  left: -17px;
  color: var(--dl-color-gray-black);
  width: 180px;
  height: 56px;
  position: absolute;
  font-size: 15px;
  font-style: normal;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 700;
  line-height: 1.55;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 34px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 34px;
  letter-spacing: 2px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-transform: none;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.home-text {
  top: -233px;
  left: -286px;
  color: var(--dl-color-gray-black);
  position: absolute;
  font-size: 18px;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 1.55;
  letter-spacing: 1px;
  text-transform: none;
  text-decoration: none;
}
.home-cta-btn1 {
  left: -248px;
  color: var(--dl-color-gray-white);
  width: 228px;
  bottom: -189px;
  height: 56px;
  position: absolute;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: 70px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 70px;
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: #c9c9c9;
}
.home-text09 {
  color: rgb(16, 16, 16);
  font-size: 15px;
  font-style: normal;
  font-family: Playfair Display;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-container04 {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: flex-start;
  background-color: #f1f1f1;
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-hakkmda {
  width: 100%;
  height: 425px;
  display: flex;
  position: relative;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-end;
  padding-top: 70px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 70px;
}
.home-text10 {
  top: 70px;
  right: 100px;
  position: absolute;
  font-size: 48px;
  font-style: normal;
  font-family: Overpass;
  font-weight: 600;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.home-container05 {
  flex: 0 0 auto;
  width: 1474px;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
}
.home-text11 {
  font-size: 18px;
  align-self: center;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 600;
  line-height: 1.55;
  letter-spacing: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-container06 {
  width: 100%;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container07 {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0);
  border-style: dashed;
  border-width: 0px;
  background-color: #ffffff;
}
.home-section-separator4 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-section-separator5 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-deneyim {
  width: 100%;
  height: 402px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: #f1f1f1;
}
.home-text12 {
  top: 54px;
  left: 95px;
  position: absolute;
  font-size: 48px;
  font-style: normal;
  font-family: Overpass;
  font-weight: 700;
  line-height: 1.55;
  letter-spacing: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-steps {
  top: 100px;
  right: 200px;
  width: 1162px;
  height: 252px;
  display: flex;
  padding: 24px;
  position: absolute;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container08 {
  left: -38px;
  width: 1379px;
  bottom: 0px;
  height: 193px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
}
.home-step {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container09 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text13 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text14 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.home-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container10 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text19 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text20 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.home-step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container11 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text24 {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text25 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  text-align: center;
}
.home-section-separator6 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0);
  border-style: dashed;
  border-width: 2px;
}
.home-subscribe {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-container13 {
  width: 100%;
  height: 287px;
  display: flex;
  padding: 0px;
  position: relative;
  align-self: stretch;
  align-items: center;
  border-color: rgba(120, 120, 120, 0);
  border-style: dashed;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
}
.home-text30 {
  top: 6px;
  right: 155px;
  position: absolute;
  font-size: 48px;
  font-family: Overpass;
  font-weight: 900;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
.home-container14 {
  flex: 0 0 auto;
  width: auto;
  height: 159px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-container15 {
  top: 55px;
  left: 240px;
  width: 1241px;
  height: 159px;
  display: flex;
  position: absolute;
  flex-direction: column;
}
.home-container16 {
  width: 100%;
  height: 63px;
  display: flex;
  align-self: center;
  margin-top: 50px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container17 {
  flex: 0 0 auto;
  width: 342px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link07 {
  font-size: 18px;
  font-family: Playfair Display;
  font-weight: 600;
  line-height: 1.55;
  margin-right: 0px;
  letter-spacing: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-container18 {
  flex: 0 0 auto;
  width: 293px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link08 {
  font-size: 18px;
  align-self: center;
  font-family: Playfair Display;
  font-weight: 600;
  line-height: 1.55;
  letter-spacing: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-container19 {
  flex: 0 0 auto;
  width: 342px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link09 {
  font-size: 18px;
  align-self: center;
  font-family: Playfair Display;
  font-weight: 600;
  line-height: 1.55;
  letter-spacing: 2px;
  text-transform: none;
  text-decoration: none;
}
.home-section-separator7 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-social-links {
  width: 111px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.home-link10 {
  display: contents;
}
.home-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-link11 {
  display: contents;
}
.home-icon08 {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.home-link12 {
  display: contents;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  align-self: flex-end;
  margin-left: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
@media(max-width: 1600px) {
  .home-container08 {
    left: -23px;
    bottom: -17px;
  }
}
@media(max-width: 1200px) {
  .home-image1 {
    right: 80px;
    bottom: 15px;
  }
  .home-hero-text-container {
    left: 25px;
  }
  .home-steps {
    top: 110px;
    right: -35px;
  }
  .home-subscribe {
    position: relative;
  }
}
@media(max-width: 991px) {
  .home-heading {
    text-align: center;
  }
  .home-hero {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image1 {
    right: 10px;
  }
  .home-heading1 {
    align-self: flex-start;
    text-align: center;
    margin-left: 10px;
  }
  .home-hero-text-container {
    left: 0px;
    width: 80%;
    align-items: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-cta-btn-container {
    align-self: flex-start;
    justify-content: flex-start;
  }
  .home-text {
    color: var(--dl-color-gray-black);
    font-size: 18px;
    align-self: flex-start;
    text-align: left;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 1.55;
    margin-left: 10px;
    margin-right: 200px;
    margin-bottom: var(--dl-space-space-fiveunits);
    letter-spacing: 1px;
    text-transform: none;
    text-decoration: none;
  }
  .home-cta-btn1 {
    align-self: flex-start;
    margin-left: 34px;
    margin-right: 34px;
  }
  .home-steps {
    right: -140px;
  }
  .home-container08 {
    left: 23px;
    right: -14px;
    bottom: -11px;
  }
}
@media(max-width: 767px) {
  .home-navbar {
    position: relative;
  }
  .home-links-container {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-image1 {
    top: 85px;
    right: 25px;
    width: 264px;
    bottom: 10px;
    height: 282px;
  }
  .home-heading1 {
    color: #040404;
    font-size: 30px;
    font-family: Overpass;
    font-weight: 900;
    line-height: 1.22;
    text-transform: none;
    text-decoration: none;
  }
  .home-hero-text-container {
    left: 20px;
  }
  .home-cta-btn {
    padding-top: 8px;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: 8px;
  }
  .home-text {
    color: var(--dl-color-gray-black);
  }
  .home-cta-btn1 {
    width: 114px;
    height: 41px;
    padding-top: 0px;
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: 0px;
  }
  .home-text09 {
    margin-top: 10px;
  }
  .home-steps {
    top: 90px;
    left: -270px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .home-container08 {
    position: relative;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-step {
    top: 0px;
    right: 0px;
    width: 100%;
    height: auto;
    position: absolute;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-container09 {
    top: -46px;
    left: 232px;
    width: 429px;
    height: 145px;
    position: absolute;
    align-items: center;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: center;
  }
  .home-text13 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text14 {
    text-align: left;
  }
  .home-step1 {
    width: 100%;
    height: auto;
    position: relative;
    flex-direction: row;
    justify-content: center;
  }
  .home-container10 {
    top: -58px;
    left: 590px;
    width: 342px;
    height: 115px;
    position: absolute;
    align-items: center;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .home-text19 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text20 {
    text-align: left;
  }
  .home-step2 {
    right: 0px;
    width: 100%;
    bottom: -68px;
    height: auto;
    position: absolute;
    flex-direction: row;
    justify-content: center;
  }
  .home-container11 {
    top: -194px;
    left: 431px;
    width: 383px;
    height: 146px;
    position: absolute;
    align-self: stretch;
    align-items: center;
    padding-top: 32px;
    flex-direction: column;
    padding-bottom: 32px;
    justify-content: space-between;
  }
  .home-text24 {
    margin-top: 32px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text25 {
    text-align: left;
  }
  .home-subscribe {
    flex-direction: column;
  }
  .home-container13 {
    width: 100%;
    align-items: center;
  }
}
@media(max-width: 479px) {
  .home-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-heading1 {
    top: 1px;
    left: -8px;
    color: rgb(4, 4, 4);
    position: absolute;
    font-size: 20px;
    font-family: Overpass;
    font-weight: 900;
    line-height: 1.22;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-halfunit);
    text-transform: none;
    text-decoration: none;
  }
  .home-hero-text-container {
    width: 478px;
    height: 317px;
  }
  .home-cta-btn {
    top: 158px;
    left: 55px;
    color: var(--dl-color-gray-black);
    width: 110px;
    height: 32px;
    font-size: 12px;
    font-style: normal;
    font-family: Playfair Display;
    font-weight: 700;
    line-height: 1.55;
    letter-spacing: 2px;
    text-transform: none;
    text-decoration: none;
    background-color: var(--dl-color-gray-white);
  }
  .home-text {
    top: 50px;
    left: -5px;
    color: var(--dl-color-gray-black);
    position: absolute;
    font-size: 9px;
    font-family: Playfair Display;
    font-weight: 500;
    line-height: 1.55;
    letter-spacing: 1px;
    text-transform: none;
    text-decoration: none;
  }
  .home-cta-btn1 {
    top: 159px;
    left: -44px;
    width: 54px;
    height: 29px;
    position: absolute;
    padding-left: 6px;
    padding-right: var(--dl-space-space-tripleunit);
  }
  .home-text09 {
    top: -6px;
    left: 8px;
    position: absolute;
    font-size: 12px;
    text-align: left;
  }
  .home-steps {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .home-container08 {
    padding-left: 0px;
  }
  .home-container09 {
    top: -27px;
    left: 225px;
    width: 307px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .home-text13 {
    margin-left: 0px;
    margin-right: 32px;
  }
  .home-container10 {
    top: -47px;
    left: 529px;
    width: 213px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .home-text19 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 0px;
    margin-right: 32px;
  }
  .home-container11 {
    top: -174px;
    left: 314px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: 32px;
  }
  .home-text24 {
    margin-left: 0px;
    margin-right: 32px;
  }
  .home-container13 {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
}
